import React from 'react';
import ReactSelect from 'react-select';

function Select(props) {
  return (
    <ReactSelect className="standard-select-container" classNamePrefix="standard-select" {...props} />
  );
}

export default Select;
