import styled, { css } from "styled-components/macro";
import Colors from "./colorswatches";

const Button = styled.a`
  text-transform: uppercase;
  border-radius: 50px;
  text-decoration: none;
  border-width: 1px;
  border-style: solid;
  padding: 0px 16px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 48px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  box-sizing: border-box;
  gap: ${(props) => (props.gap ? `${props.gap}px` : "0")};

  ${(props) => (props.fullWidth ? "width: 100%;" : "")}

  ${(props) => {
    switch (props.type) {
      case "primary":
        return css`
          color: ${Colors.darkGrey100};
          background-color: ${Colors.white};
          border-color: ${Colors.darkGrey100};

          svg {
            fill: ${Colors.darkGrey100};
          }

          &:hover {
            background-color: ${Colors.gold100};
            color: ${Colors.darkGrey100};
            border-color: ${Colors.darkGrey300};

            svg {
              fill: ${Colors.darkGrey100};
            }
          }
        `;
      case "primary-paige":
        return css`
          color: ${Colors.paigeBrand200};
          background-color: ${Colors.white};
          border-color: ${Colors.paigeBrand200};

          svg {
            fill: ${Colors.paigeBrand200};
          }

          &:hover {
            background-color: ${Colors.paigeBrand100};
            color: ${Colors.white};
            border-color: ${Colors.paigeBrand100};

            svg {
              fill: ${Colors.white};
            }
          }
        `;
      case "primary-selected":
        return css`
          background-color: ${Colors.gold100};
          color: ${Colors.darkGrey100};
          border-color: ${Colors.darkGrey300};

          svg {
            fill: ${Colors.darkGrey100};
          }

          &:hover {
            background-color: ${Colors.gold200};
            color: ${Colors.darkGrey100};
            border-color: ${Colors.darkGrey300};

            svg {
              fill: ${Colors.darkGrey100};
            }
          }
        `;
      case "primary-paige-selected":
        return css`
          background-color: ${Colors.paigeBrand200};
          color: ${Colors.white};
          border-color: ${Colors.paigeBrand200};

          svg {
            fill: ${Colors.white};
          }

          &:hover {
            background-color: ${Colors.paigeBrand100};
            color: ${Colors.white};
            border-color: ${Colors.paigeBrand100};

            svg {
              fill: ${Colors.white};
            }
          }
        `;
      case "light":
        return css`
          color: ${Colors.darkGrey100};
          background-color: ${Colors.white};
          border-color: ${Colors.darkGrey100};

          svg {
            fill: ${Colors.darkGrey100};
          }

          &:hover {
            background-color: ${Colors.gold100};
            color: ${Colors.darkGrey100};

            svg {
              fill: ${Colors.darkGrey100};
            }
          }
        `;
      case "dark":
        return css`
          color: ${Colors.white};
          background-color: ${Colors.darkGrey200};
          border-color: ${Colors.darkGrey200};

          svg {
            fill: ${Colors.white};
          }

          &:hover {
            background-color: ${Colors.gold100};
            color: ${Colors.darkGrey100};
            border: 1px solid ${Colors.darkGrey300};

            svg {
              fill: ${Colors.darkGrey100};
            }
          }
        `;
      case "dark-paige":
        return css`
          color: ${Colors.white};
          background-color: ${Colors.darkGrey200};
          border-color: ${Colors.darkGrey200};

          svg {
            fill: ${Colors.white};
          }

          &:hover {
            background-color: ${Colors.darkGrey100};
            color: ${Colors.white};
            border: 1px solid ${Colors.darkGrey300};

            svg {
              fill: ${Colors.darkGrey100};
            }
          }
        `;
      case "system-error":
        return css`
          border-radius: 0;
          background-color: ${Colors.red200o40};
          font-size: 14px;
          font-weight: 500;
          height: auto;
          padding: 10px 16px;

          &:hover {
            background-color: ${Colors.red200o70};
          }
        `;
      case "system-success":
        return css`
          border-radius: 0;
          background-color: ${Colors.green100o40};
          font-size: 14px;
          font-weight: 500;
          height: auto;
          padding: 10px 16px;

          &:hover {
            background-color: ${Colors.green100o40};
          }
        `;
      case "ghost":
        return css`
          text-transform: none;
          flex-direction: row;
          gap: 10px;
          padding: 10px;
          border-radius: 0;
          background-color: transparent;
          font-size: 16px;
          font-weight: 400;
          height: auto;
          color: ${Colors.darkGrey100};

          svg {
            fill: ${Colors.darkGrey100};
          }

          &:hover {
            color: ${Colors.gold200};
            background-color: transparent;

            svg {
              fill: ${Colors.gold200};
            }
          }
        `;
    }
  }}

  ${(props) => {
    if (props.disabled) {
      let styling = `
        pointer-events: none;
      `;
      if (props.type === "ghost") {
        styling += `
          opacity: 0.7;
          cursor: not-allowed;
          svg {
            fill: ${Colors.darkGrey100};
          }
          &:hover {
            color: ${Colors.darkGrey100};
            svg {
              fill: ${Colors.darkGrey100};
            }
          }
        `;
      } else {
        styling += `
          background-color: ${Colors.darkGrey200};
          border: none;
          &:hover {
            background-color: ${Colors.darkGrey200};
            color: ${Colors.white};
          }
          opacity: 0.7;
          cursor: not-allowed;
        `;
      }

      return css`
        ${styling}
      `;
    }
  }}
`;

export default Button;
